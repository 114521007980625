import request from '../libs/axios'

export function getUsers(params) {
  return request({
    url: '/api/users/index',
    method: 'get',
    params,
  })
}

export function storeUser(data) {
  return request({
    url: '/api/users/index',
    method: 'post',
    data,
  });
}

export function showUser(data) {
  return request({
    url: `/api/users/index/${data.id}`,
    method: 'get',
    params: data.params
  });
}

export function updateUser(data) {
  return request({
    url:`/api/users/index/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteUser(data) {
  return request({
    url: `/api/users/index/${data.id}`,
    method: 'delete',
  });
}

export function getRoles(params) {
  return request({
    url: '/api/roles/index',
    method: 'get',
    params,
  })
}

export function getTeachers(params) {
  return request({
    url: '/api/users/teachers',
    method: 'get',
    params,
  })
}

export function getSchoolTypes() {
  return request({
    url: '/api/users/school-types',
    method: "get",
  })
}

export function storeTeacher(data) {
  return request({
    url: '/api/users/teachers',
    method: 'post',
    data,
  })
}

export function updateTeacher(data) {
  return request({
    url: `/api/users/teachers/${data.get('id')}`,
    method: 'post',
    data,
  })
}
