import request from '../libs/axios'

export function getRegions(params) {
  return request({
    url: '/api/region/index',
    method: 'get',
    params,
  })
}

export function storeRegion(data) {
  return request({
    url: '/api/region/index',
    method: 'post',
    data,
  });
}

export function showRegion(data) {
  return request({
    url: `/api/region/index/${data.id}`,
    method: 'get',
    data,
  });
}

export function updateRegion(data) {
  return request({
    url:`/api/region/index/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteRegion(data) {
  return request({
    url: `/api/region/index/${data.id}`,
    method: 'delete',
  });
}

export function getAllRegions() {
  return request({
    url: '/api/regions',
    method:'get',
  })
}

export function getRegionRayons(params) {
  return request({
    url: '/api/get-rayons-by-region',
    method: 'get',
    params,
  })
}
