<template>
  <section class="regions">
    <div class="w-100 mt-3">
      <custom-filter @handleSearch="handleSearch" :search.sync="filters.search" @handleAdd="handleAdd" :createButton="false">
        <template slot="customFilters">
          <b-col cols="2">
            <b-form-group>
              <b-form-select
                  v-model="filters.nomination_id"
                  :options="nominations"
                  text-field="title"
                  value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <b-form-select :options="statuses" text-field="name" value-field="id" v-model="filters.status_id"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col :cols="2">
            <b-form-group label="">
              <b-form-select
                  v-model="filters.region"
                  :options="allRegions"
                  text-field="nameuz"
                  value-field="id"
                  @input="fetchRayons"
              />
            </b-form-group>
          </b-col>
          <b-col :cols="2">
            <b-form-group label="">
              <b-form-select
                  v-model="filters.rayon"
                  :options="rayons"
                  text-field="region"
                  value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col :cols="2">
            <b-form-group label="">
              <b-form-select
                  v-model="filters.jury"
                  :options="juries"
                  text-field="full_name"
                  value-field="id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-button variant="warning" size="sm" @click="getClear">
              <b-icon icon="arrow-repeat"></b-icon>
            </b-button>
          </b-col>
          <b-col cols="1">
            <b-button variant="info" size="sm" @click="getSearch">
              {{$t('message.Search')}}
            </b-button>
          </b-col>
          <b-col cols="1">
            <b-button variant="success" size="sm" @click="generateExcel">
              Excel
            </b-button>
          </b-col>
        </template>
      </custom-filter>
      <div class="w-100 mb-1">
        <h6>{{$t('message.TotalCount')}} : {{total}}</h6>
      </div>
      <b-card>
        <b-table :items="datas" :fields="fields" :no-local-sorting="true" @sort-compare="sortCompare" @sort-changed="sortChanged">
          <template #cell(user)="{item}">
            <span>
              {{item.user.first_name}} {{item.user.last_name}}
            </span>
          </template>
          <template #cell(user_marks)="{item}">
            <span v-html="item.user_marks_label"></span>
          </template>
          <template #cell(action)="data">
            <div class="d-flex">
<!--              <b-button v-if="data.item.status.status_name === 'onprogress'" variant="warning" size="sm" class="ml-1" @click="handleReject(data.item.id)">{{$t('message.Reject')}}</b-button>-->
<!--              <b-button v-if="data.item.status.status_name === 'onprogress'" variant="success" size="sm" class="ml-1" @click="handleReject(data.item.id)">{{$t('message.Accept')}}</b-button>-->
              <b-button variant="primary" size="sm" class="ml-1" @click="handleEdit(data.item.id)" >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button class="ml-1" variant="danger" size="sm" @click="handleDelete(data.item.id)" >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end mt-3">
          <b-pagination
              v-model="pagination.page"
              hide-goto-end-buttons
              :total-rows="total"
              :per-page="pagination.pageSize"
              @input="fetchList()"
          />
        </div>
      </b-card>
    </div>
    <b-modal id="modal-lg" size="xl" scrollable hide-footer :title="modalTitle" v-model="modalVisible" >
      <Form :id="id" @saved="fetchList" :juries="juries" :statuses="statuses" :questions="questions" :mark="true" />
    </b-modal>
    <b-modal size="lg" hide-footer :title="$t('message.CauseOfReject')" v-model="RejectModal">
      <div class="w-100">
        <b-form-group>
          <b-form-textarea
              id="textarea"
              v-model="reject.cause"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="w-100 mt-2 d-flex justify-content-end">
        <b-button variant="danger" @click="getRejectApplication" class="mr-1">{{$t('message.Save')}}</b-button>
        <b-button variant="dark" @click="RejectModal=false">{{$t('message.Cancel')}}</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  getApplicationMarks,
  deleteApplication,
  getStatuses,
  rejectApplication,
  displayStatusesAll,
} from '@/api/application'
import { listTemplate } from '@/utils/mixins/listTemplate'
import CustomFilter from '@/views/components/CustomFilter'
import Form from './Form'
import { getNominations,generateMarksExcel } from '@/api/nomination'
import { getAllRegions, getRegionRayons } from '@/api/region'
import { getUsers } from '@/api/users'

const actions = {
  get: getApplicationMarks,
  delete: deleteApplication,
}

export default {
  name: 'Marks',
  components:{
    CustomFilter,
    Form,
  },
  mixins: [
    listTemplate,
  ],
  created() {
    getStatuses().then(res => {
      this.statuses = res.data.data;
    })
    this.fetNominations()
    this.fetchRegions()
    this.fetchJuries()
  },
  data() {
    return {
      actions,
      fields: [
        {key:'id', label: this.$t('ID'), sortable: true },
        {key:'nomination.title', label: this.$t('message.Nomination') },
        {key:'user', label: this.$t('message.Nominant') },
        {key:'user_marks', label: this.$t('message.Mark'), sortable: true },
        {key:'status.name', label: this.$t('message.Status') },
        {key:'action', label: this.$t('message.Action') },
      ],
      juries: [],
      statuses: [],
      questions: [],
      filters: {
        status_id: null,
        search: null,
        mark: true,
        nomination_id: null,
        region: null,
        rayon: null,
        jury: null,
        sortDescById: null,
        sortDescByMark: null,
        export: null,
        responseType: 'json',
        stage_id: this.$route.params.stageId,
      },
      RejectModal: false,
      reject: {
        cause: null,
        application_id: null,
      },
      nominations: [],
      rayons: [],
      allRegions: [],
    }
  },
  methods: {
    getSearch() {
      this.pagination.page = 1;
      this.fetchList()
    },
    getClear() {
      this.pagination.page = 1
      this.filters.search = null
      this.filters.rayon = null
      this.filters.region = null
      this.filters.status_id = null
      this.filters.nomination_id = null
      this.filters.jury = null
      this.fetchList()
    },
    // handleReject(id) {
    //   this.reject.application_id = id;
    //   this.RejectModal = true;
    // },
    getRejectApplication() {
      rejectApplication({ ...this.reject, status: 'rejected' }).then(() => {
        this.RejectModal = false
        this.fetchList()
      })
    },
    sortChanged(e) {
      console.log(e)
      if (e.sortBy === 'id') {
        this.filters.sortDescById = e.sortDesc;
      }
      if (e.sortBy === 'totalMark' || e.sortBy === 'user_marks') {
        this.filters.sortDescByMark = e.sortDesc
      }
      this.fetchList()
    },
    sortCompare(e) {
      console.log(e)
    },
    fetNominations() {
      getNominations().then(res => {
        this.nominations = res.data.data.data
        this.nominations.unshift({ title: this.$t('message.Nomination'), id: null })
      })
    },
    fetchRegions() {
      getAllRegions().then(res => {
        this.allRegions = res.data.data.result
        this.allRegions.unshift({ nameuz: this.$t('message.Region'), id: null })
      })
    },
    fetchRayons() {
      getRegionRayons({ region_id: this.filters.region }).then(res => {
        this.rayons = res.data.data
        this.rayons.unshift({ region: this.$t('message.Rayon'), id: null })
      })
    },
    fetchJuries() {
      getUsers({ role_id: 3, pageSize: 30, page: 1 }).then(res => {
        this.juries = res.data.data.data.map(item => ({ id: item.id, full_name: (`${item.last_name} ${item.first_name}`) }))
      })
    },
    generateExcel() {
      this.$store.dispatch('settings/setLoading',true);
      generateMarksExcel(this.filters).then(({data}) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click()

      }).finally(() => this.$store.dispatch('settings/setLoading',false))
    },
    handleSearch(search) {
      this.filters.search = search;
    },
    fireAllStautses() {
      this.$swal({
        title: this.$t('message.areYouSure'),
        text: this.$t('message.wontReturn'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('message.Edit'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          displayStatusesAll()
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: this.$t('message.Cancelled'),
            text: this.$t('message.yrDataSafe'),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
